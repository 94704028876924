import React, { useMemo } from 'react';
import compact from 'lodash/compact';

import { DropzoneTabType, DropzoneTabOnChange } from './DropzoneTabs.types';

import { useCurrentUser } from '../../../auth/hooks/useAuth';

import { DropzoneTab, DropzoneTabItem, DropzoneTabFiles } from '../DropzoneTab';

import { FileAttachmentsPermissions } from '../../../main/fileAttachments/fileAttachmentsConstants';

import { words } from '../../../locales/keys';

interface DropzoneTabsProps {
  allFiles: DropzoneTabFiles;
  activeTab: DropzoneTabType;
  onChange: DropzoneTabOnChange;
  withLd?: boolean;
}

function DropzoneTabs({
  allFiles,
  activeTab,
  onChange,
  withLd
}: DropzoneTabsProps) {
  const currentUser = useCurrentUser();

  const withLdTab =
    withLd &&
    currentUser?.currentTeam?.useLdApiAt &&
    currentUser?.hasPermissions(
      FileAttachmentsPermissions.READ_DROPZONE_FILE_ATTACHMENT_LD_TAB
    );

  const tabs = useMemo<DropzoneTabItem[]>(
    () =>
      compact([
        { name: 'general', i18nText: words.general },
        { name: 'drawing', i18nText: words.drawings },
        { name: 'reference', i18nText: words.references },
        { name: 'texture', i18nText: words.textures },
        { name: '3ds', i18nText: words.threeDs },
        withLdTab ? { name: 'ld', i18nText: words.ld } : null
      ]),
    [withLdTab]
  );

  return (
    <nav className="flex space-x-2 mb-2">
      {tabs.map((tab) => (
        <DropzoneTab
          key={`dropzone-field-list-tab-${tab.name}`}
          active={activeTab === tab.name}
          allFiles={allFiles}
          item={tab}
          onChange={onChange}
        />
      ))}
    </nav>
  );
}

export default DropzoneTabs;
